import React from 'react';
import Header from '../components/Header';

export default function Profile() {
  return (
    <>
      <Header />
      <h1>Profile</h1>
    </>
  );
}
