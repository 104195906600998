import React from 'react';
import Header from '../components/Header';

export default function NotFound() {
  return (
    <>
      <Header />
      <h1>Not found</h1>
    </>
  );
}
