import React from 'react';
import Header from '../components/Header';

export default function WatchedVideos() {
  return (
    <>
      <Header />
      <div>WatchedVideos</div>
    </>
  );
}
